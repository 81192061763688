import { Flex, Image, Text } from '@chakra-ui/react'
import { BsMic } from 'react-icons/bs'
import { MdOutlineImagesearchRoller } from 'react-icons/md'
import { PiChats, PiShareFat } from 'react-icons/pi'
import { SiWhatsapp } from 'react-icons/si'
import Box from './Box'

import { colorsLight } from '@/styles/colors'
import { TbUserEdit } from 'react-icons/tb'

interface Props {}

export default function CardBox({}: Props) {
  return (
    <Flex
      align={'center'}
      w={'100%'}
      maxW={1440}
      pb={['60px', null, '120px']}
      px={'20px'}
      as={'section'}
      gap={'120px'}
      flexDir={'column'}
      color={colorsLight.primary.onPrimary}
    >
      <Flex flexDir={'column'} gap={'64px'}>
        <Flex flexDir={'column'} align={'center'}>
          <Text
            as={'h1'}
            fontSize={['xl', null, '3xl']}
            color={colorsLight.primary.main}
          >
            <Text as={'strong'}>Personalize sua IA</Text> de maneira simples e
            ágil
          </Text>
          <Text fontSize={'lg'} color={colorsLight.primary.main}>
            Customize completamente a sua IA e experimente uma série de
            benefícios adicionais.
          </Text>
        </Flex>
        <Box
          title="Crie um agente personalizado "
          description="Crie um agente, caracterize sua instrução e escolha um tom de voz específico."
          icon={TbUserEdit}
          imageElement={
            <Flex
              bgColor={'blackAlpha.900'}
              borderRadius={'12px'}
              px={'20px'}
              pt={'20px'}
              pb={'14px'}
              justify={'center'}
            >
              <Flex
                position={'relative'}
                mt={[0, null, 'calc(5px - 60px)', 'calc(45px - 160px)']}
              >
                <Image
                  w={['100%', null, '90%']}
                  src={'https://d1k1f4n2h095ym.cloudfront.net/card-box-6.png'}
                  alt={'Crie e compartilhe sua base'}
                />
              </Flex>
            </Flex>
          }
          reverse
        />

        <Box
          title="Personalize seu chat"
          description="Customize o seu Web Chat da maneira que preferir e deixe com a cara da sua empresa."
          icon={MdOutlineImagesearchRoller}
          imageElement={
            <Flex bgColor={'blackAlpha.900'} borderRadius={'12px'} flex={1.5}>
              <Flex
                position={'relative'}
                mb={[0, null, 'calc(5px - 60px)', 'calc(5px - 115px)']}
              >
                <Image
                  w={['100%', null, '90%']}
                  src={'https://d1k1f4n2h095ym.cloudfront.net/card-box-8.png'}
                  alt={'Crie e compartilhe sua base'}
                />
              </Flex>
            </Flex>
          }
          reverse
        />
        <Box
          title="Envie respostas em aúdio"
          description="Transmita mensagens de áudio com a ajuda do seu agente GPT Max."
          icon={BsMic}
          imageElement={
            <Flex
              bgColor={'blackAlpha.900'}
              borderRadius={'12px'}
              flex={1.5}
              px={[0, null, null, '60px']}
              pt={'20px'}
            >
              <Flex
                position={'relative'}
                mt={[0, null, 'calc(5px - 60px)', 'calc(5px - 120px)']}
              >
                <Image
                  w={['100%']}
                  src={'https://d1k1f4n2h095ym.cloudfront.net/card-box-5.png'}
                  alt={'Crie e compartilhe sua base'}
                />
              </Flex>
            </Flex>
          }
        />
      </Flex>

      <Flex flexDir={'column'} gap={'64px'}>
        <Flex
          flexDir={'column'}
          align={'center'}
          color={colorsLight.primary.main}
        >
          <Text
            as={'h1'}
            fontSize={['xl', null, '3xl']}
            color={colorsLight.primary.main}
          >
            Uma <Text as={'strong'}>proposta pioneira</Text> no Brasil
          </Text>
          <Text fontSize={'lg'}>
            Inteligência Artificial trabalhando ao seu favor de uma forma jamais
            vista antes.
          </Text>
        </Flex>

        <Box
          title="Crie e compartilhe seu agente"
          description="Desenvolva a sua própria base de dados personalizada para alimentar a sua inteligência artificial. Faça o upload de documentos e imagens e compartilhe-os facilmente por meio de um link ou pelo MarketShare."
          icon={PiShareFat}
          imageElement={
            <Flex position={'relative'} flex={1.5} pl={[0, null, '32px']}>
              <Flex pos={'absolute'} top={0} left={-70} opacity={0.7}></Flex>
              <Image
                w={'100%'}
                src={'https://d1k1f4n2h095ym.cloudfront.net/card-box-1.png'}
                alt={'Crie e compartilhe sua base'}
              />
            </Flex>
          }
        />

        <Box
          title="Crie seu próprio webchat"
          description="Observe o seu agente de IA prestando atendimento aos clientes diretamente em seu site por meio de um webchat."
          icon={PiChats}
          imageElement={
            <Flex
              position={'relative'}
              mt={['calc(45px - 60px)', null, null, 'calc(45px - 120px)']}
              flex={1.5}
            >
              <Image
                w={'100%'}
                src={'https://d1k1f4n2h095ym.cloudfront.net/card-box-2.png'}
                alt={'Crie e compartilhe sua base'}
              />
            </Flex>
          }
          reverse
        />

        <Box
          title="Integre com o Whatsapp"
          description="Integre o seu agente de IA ao WhatsApp da sua empresa e proporcione atendimento humanizado a milhares de clientes de forma simultânea."
          icon={SiWhatsapp}
          imageElement={
            <Flex bgColor={'blackAlpha.900'} borderRadius={'12px'} flex={1.5}>
              <Flex
                position={'relative'}
                mt={[0, null, 'calc(45px - 60px)', 'calc(45px - 120px)']}
              >
                <Image
                  w={'100%'}
                  src={'https://d1k1f4n2h095ym.cloudfront.net/card-box-7.png'}
                  alt={'Crie e compartilhe sua base'}
                />
              </Flex>
            </Flex>
          }
        />
      </Flex>
    </Flex>
  )
}
